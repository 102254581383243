/* eslint-disable no-console */
import { useLazyQuery } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Badge, Button } from 'antd';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { CaretRight, Export, SketchLogo } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MEMBERS_ONLY,
  TRACK_VALUE,
  VIDEO_FROM_TYPES
} from '../../../../common/constants';
import usePageGroup from '../../../../common/usePageGroup';
import { decode } from '../../../../common/utils';
import { PLAYLIST_NEXT_VIDEO } from '../../../../graphql/Queries';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import CustomAnchor from '../../../CustomAnchor';
import VideoInfoLoading from '../../../skeleton/VideoInfoLoading';
import CustomPlayer from '../customPlayer';
import ModalComponent from '../customPlayer/ModalComponent';
import ShareSheet from '../customPlayer/ShareSheet';
import TagCarousel from '../tagModule/TagCarousel';

function VideoInfo({
  category,
  categoryHref = '#',
  title,
  tags = [],
  description,
  videos = [],
  videosWatchTimes,
  isPremium,
  status,
  videoMainUrl,
  slug: videoSlug,
  previewVideo,
  instanceData,
  requiredPlan,
  videoAsset
}) {
  const {
    state: { currentUser }
  } = useApp();
  const { trackEvent } = useMatomo();
  const router = useRouter();
  const { data: session } = useSession();
  const [visible, setVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const { query: { next } = {} } = router;

  const { getRandomPath } = usePageGroup();

  const [nextVideoData, setNextVideoData] = useState(null);

  const token = session?.accessToken;

  const queryConfigs = {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        token
      }
    }
  };

  const [getPlaylistNextVideo] = useLazyQuery(
    PLAYLIST_NEXT_VIDEO,
    queryConfigs
  );

  // Uncomment if redirection is needed from videoPlayer to subscription plan page in future
  // useEffect(() => {
  //   const { pathname } = router;
  //   if (pathname === '/videos/[slug]') {
  //     if (isEmpty(videoMainUrl) && isEmpty(previewVideo)) {
  //       window.location.href = `${
  //         getRandomPath(PAGE_GROUPS.SUBSCRIPTION) ?? ROUTES.MANAGE_PLAN
  //       }?redirectWebUrl=${encodeURL(
  //         `${process.env.NEXT_APP_WEB_URL}${ROUTES.VIDEOS}/${videoSlug}`
  //       )}`;
  //     }
  //   }
  // }, [previewVideo, videoSlug, videoMainUrl, router, getRandomPath]);

  useEffect(() => {
    if (next && token) {
      (async () => {
        try {
          const { type, order, slug } = decode(next);
          const defaultProps = {
            slug,
            type
          };
          switch (type) {
            case VIDEO_FROM_TYPES.PLAYLIST:
              {
                const { data, errors } = await getPlaylistNextVideo({
                  variables: {
                    order,
                    slug
                  }
                });
                if (data && !errors && data?.playlistNextVideo) {
                  const { slug: nextSlug, order: nextOrder } =
                    data?.playlistNextVideo;
                  setNextVideoData({
                    ...defaultProps,
                    videoSlug: nextSlug,
                    order: nextOrder
                  });
                }
              }
              break;
            default:
              break;
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      })();
    }
  }, [next, token, getPlaylistNextVideo]);

  useEffect(() => {
    setShareUrl(window?.location?.href);
  }, []);

  const shareHandler = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleTopicsAnalytics = (matomoCategory, action) => {
    trackEvent({
      category: matomoCategory,
      action,
      name: JSON.stringify({
        action,
        email: currentUser?.email ?? '',
        topics: instanceData?.topics
      }),
      value: TRACK_VALUE.SUCCESS
    });
    gaEvent({
      category: matomoCategory,
      action,
      name: JSON.stringify({
        action,
        userId: currentUser?.id ?? '',
        topics: instanceData?.topics
      }),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <>
      <ModalComponent
        className="share-popup"
        title="Share"
        open={visible}
        onCancel={handleCancel}
        footer={false}
        mask={false}
      >
        <ShareSheet shareUrl={shareUrl} videos={videos} />
      </ModalComponent>
      <section className="video-info-section">
        <CustomPlayer
          nextVideo={nextVideoData}
          VIDEOS={videos}
          watchTime={videosWatchTimes?.[videos?.[0]?.id] ?? 0}
          isPremium={isPremium}
          videoSlug={videoSlug}
          previewVideo={previewVideo}
          status={status}
          requiredPlan={requiredPlan}
          tags={tags}
          videoAsset={videoAsset}
        />
        <div className="container">
          <div className="video-info-wrapper">
            <div className="videoinfo-title">
              {isPremium ? (
                <Badge className="badge-md text-white-90 bg-n-900 premium-tag ml-4">
                  <SketchLogo size={18} />
                  <span className="text-label-xs">{MEMBERS_ONLY}</span>
                </Badge>
              ) : null}
              {category ? (
                <CustomAnchor
                  href={categoryHref}
                  onClick={() =>
                    handleTopicsAnalytics(
                      MATOMO_CATEGORY.TOPICS,
                      MATOMO_ACTION.TOPICS_CARD_CLICKED
                    )
                  }
                >
                  <Badge className="badge-xs video-badge">{category}</Badge>
                </CustomAnchor>
              ) : null}
              {title ? <div className="display-h4">{title}</div> : null}
            </div>
            <div className="video-button-wrapper">
              <Button
                className="videoaction"
                type="primary"
                icon={<Export size={24} weight="light" />}
                onClick={shareHandler}
              >
                Share
              </Button>
            </div>
            {tags?.length > 0 ? (
              <div className="video-info-tags">
                <TagCarousel
                  className="text-small tag-s bg-n-700 b-0 text-white-90"
                  data={tags}
                  icon={<CaretRight size={16} />}
                />
              </div>
            ) : null}
            {description ? (
              <p className="paragraph text-black-90 w-full">{description}</p>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
}

VideoInfo.Loading = function Loading() {
  return <VideoInfoLoading />;
};

export default VideoInfo;
