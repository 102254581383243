/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ConfigProvider, Segmented, Select, Slider } from 'antd';
import { debounce } from 'lodash';
import isNumber from 'lodash/isNumber';
import nth from 'lodash/nth';
import { useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  ClosedCaptioning,
  Gauge,
  GearSix,
  Headphones,
  Pause,
  Play,
  SpeakerHigh,
  SpeakerSlash,
  VideoCamera,
  X
} from 'phosphor-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MODULE_TYPES,
  PLAYBACK_RATES,
  ROUTES,
  STATIC_DATA_KEYS,
  SUBSCRIPTION_PLAN_KEYS,
  TRACK_KIND,
  TRACK_MODE,
  TRACK_VALUE,
  VIDEO_PLAYER_NAME,
  defaultSubtitle,
  staticUiLabels
} from '../../../../common/constants';
import useStaticData from '../../../../common/useStaticData';
import { Storage, withNextVideoQuery } from '../../../../common/utils';
import { UPDATE_PODCAST_PLAY_TIME } from '../../../../graphql/Mutations';
import { SUBSCRIPTION_PLANS } from '../../../../graphql/Queries';
import { gaEvent } from '../../../../lib/gtag';
import { useApp } from '../../../../providers/AppContext';
import PreviewIndicator from '../../../PreviewIndicator';
import SignupCTAModal from '../../../SignupCTAModal';
import SubscriptionBlock from '../subscriptionModules/SubscriptionBlock';
import Chapters from './Chapters';
import DurationTime from './DurationTime';
import LoaderComponent from './LoaderComponent';
import Progress from './Progress';

const ReactPlayer = dynamic(() => import('react-player/lazy'), {
  loading: () => (
    <LoaderComponent setHeight="auto" className="absolute inset-0" />
  ),
  ssr: false
});

const PosterImage = dynamic(() => import('./PosterImage'), {
  loading: () => null,
  ssr: false
});
const IOSWrapper = dynamic(() => import('./IOSWrapper'), {
  loading: () => null,
  ssr: false
});

const { Option } = Select;
let videoWatchTime;

function CustomPodcastPlayer(props) {
  const { data: sessionData } = useSession();
  const {
    state: { accessToken, currentUser, isAuthenticated }
  } = useApp();
  const { trackEvent } = useMatomo();
  const router = useRouter();
  const isAuth = !!sessionData;
  const isVideoWatched = useRef(false);
  const showUnmuteButton = useRef(true);
  const maxAllowedWatchCount = useRef(3);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const {
    VIDEOS,
    watchTime,
    nextVideo,
    showModal = true,
    moduleType,
    audios,
    status,
    requiredPlan,
    isPremium
  } = props;
  const videoDuration = '0';
  const [activeCaption, setActiveCaption] = useState(null);
  const [captionVisible, setCaptionVisible] = useState(false);
  const [subtitleTracksData, setSubtitleTracksData] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [chapterTitle, setChapterTitle] = useState(null);
  const video = VIDEOS?.[0]?.url;
  const audio = audios?.[0]?.url;
  const videoTitle = VIDEOS?.[0]?.title;
  const videoTracks = VIDEOS?.[0]?.tracks;
  const previewVideo = VIDEOS?.[0]?.previewVideo;
  const previewAudio = audios?.[0]?.previewAudio;
  const [playbackRate, setPlaybackRate] = useState(1);
  const [playerRef, setPlayerRef] = useState(null);
  const [playing, setPlaying] = useState(true);
  const [duration, setDuration] = useState(videoDuration);
  const [showControls, setShowControls] = useState(true);
  const [mobileControls, setMobileControls] = useState(true);
  const [isVideoPlayed, setIsVideoPlayed] = useState(true);
  const [muted, setMuted] = useState(isIOS || isSafari);
  const [volume, setVolume] = useState(1);
  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [playbackOpen, setPlaybackOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);
  const [subtitleOpen, setSubtitleOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [alignValue, setAlignValue] = useState('Audio');
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [planNames, setPlanNames] = useState([]);
  const [lowestPrice, setLowestPrice] = useState();

  const uiLabels = useStaticData(`${STATIC_DATA_KEYS.UI_LABELS}/en.json`);
  const {
    PREMIUM_BLOCK_BUTTON,
    PREMIUM_BLOCK_DESC,
    PREMIUM_BLOCK_TITLE,
    PREMIUM_BLOCK_IMAGE,
    WITHOUT_LOGIN_PREMIUM_BLOCK_BUTTON,
    WITHOUT_LOGIN_PREMIUM_BLOCK_DESC,
    WITHOUT_LOGIN_PREMIUM_BLOCK_LOGIN_TEXT,
    WITHOUT_LOGIN_PREMIUM_BLOCK_LOGIN_IMAGE
  } = uiLabels;

  const { data } = useQuery(SUBSCRIPTION_PLANS, {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        token: accessToken
      }
    },
    onCompleted: (res) => {
      const prices = res.subscriptionPlans.subscriptionPlans.map(
        (plan) => plan.amount.usd.price
      );
      const lowPrice = Math.min(...prices);
      setLowestPrice(lowPrice);

      const uniqueNames = [
        ...new Set(
          res.subscriptionPlans.subscriptionPlans.map((item) => item.name)
        )
      ];
      setPlanNames(uniqueNames);
    }
  });

  useEffect(() => {
    let id;
    if (showControls && isReady) {
      id = setTimeout(() => {
        setShowControls(false);
      }, 3000);
    }

    return () => {
      if (id) clearTimeout(id);
    };
  }, [showControls, isReady]);

  useEffect(() => {
    if (isReady && window.location !== window.parent.location) {
      setPlaying(false);
    }
  }, [isReady, playing]);

  const settingClick = () => {
    setSettingOpen(!settingOpen);
    setPlaybackOpen(false);
    setSubtitleOpen(false);
  };

  const playbackClick = () => {
    setPlaybackOpen(!playbackOpen);
    setSettingOpen(false);
    setSubtitleOpen(false);
  };
  const outsideClick = () => {
    setPlaybackOpen(false);
    setSettingOpen(false);
    setSubtitleOpen(false);
  };
  const subtitleClick = () => {
    setSubtitleOpen(!subtitleOpen);
    setPlaybackOpen(false);
    setSettingOpen(false);
  };

  const [updatePodcastPlayTime] = useMutation(UPDATE_PODCAST_PLAY_TIME, {
    onError() {}
  });

  useEffect(() => {
    if (watchTime) {
      if (playerRef) playerRef.seekTo(watchTime ?? 0);
    }
  }, [watchTime, playerRef]);

  // useEffect(() => {
  //   if (!isAuth && showModal) {
  //     (async () => {
  //       try {
  //         const resp = await staticDataApi(`${STATIC_DATA_KEYS.CONFIGS}.json`);
  //         const jsonData = resp?.data;
  //         if (jsonData) {
  //           const { VIDEO_SIGNUP_POPUP_INTERVAL } = jsonData ?? {};
  //           maxAllowedWatchCount.current = +(
  //             VIDEO_SIGNUP_POPUP_INTERVAL?.value ?? 3
  //           );
  //         }
  //       } catch (err) {
  //         // eslint-disable-next-line no-console
  //         console.log('ERROR', err);
  //       }
  //     })();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isAuth]);

  const updateWatchTime = React.useCallback(
    async (time, track = true) => {
      const id = audios?.[0]?.id;
      if (!id || !sessionData?.accessToken) return;

      let newWatchTime = time;
      if (Math.floor(time) === Math.floor(duration)) {
        newWatchTime = 0;
      }

      try {
        await updatePodcastPlayTime({
          variables: {
            data: {
              podcastId: id,
              playTime: newWatchTime ?? 0
            }
          },
          context: {
            headers: {
              token: sessionData?.accessToken
            }
          }
        });
        if (track)
          trackEvent({
            category: previewVideo
              ? MATOMO_CATEGORY.PREVIEW_PODCAST_VIDEO
              : MATOMO_CATEGORY.PODCAST_VIDEO,
            action: MATOMO_ACTION.AVERAGE_WATCH_TIME,
            name: JSON.stringify({
              ...(sessionData?.user && {
                email: sessionData?.user?.email
              }),
              siteId: process.env.NEXT_APP_MATOMO_SITE_ID,
              mediaId: VIDEOS?.[0]?.id,
              mediaTitle: VIDEOS?.[0]?.title,
              playerName: VIDEO_PLAYER_NAME,
              mediaType: MODULE_TYPES.VIDEO_PLAYER,
              mediaResource: VIDEOS?.[0]?.resources,
              mediaStatus: status,
              mediaLength: VIDEOS?.[0]?.time,
              mediaProgress: played,
              newWatchTime
            }),
            value: TRACK_VALUE.SUCCESS
          });
        gaEvent({
          category: MATOMO_CATEGORY.PODCAST_VIDEO,
          action: MATOMO_ACTION.AVERAGE_WATCH_TIME,
          name: JSON.stringify({
            ...(sessionData?.user && {
              userId: sessionData?.user?.id
            }),
            mediaId: VIDEOS?.[0]?.id,
            mediaTitle: VIDEOS?.[0]?.title,
            playerName: VIDEO_PLAYER_NAME,
            mediaType: MODULE_TYPES.VIDEO_PLAYER,
            mediaResource: VIDEOS?.[0]?.resources,
            mediaStatus: status,
            mediaLength: VIDEOS?.[0]?.time,
            mediaProgress: played,
            newWatchTime
          }),
          value: TRACK_VALUE.SUCCESS
        });
      } catch (error) {
        return error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatePodcastPlayTime, sessionData, playing, duration]
  );

  const debounceUpdateWatchTime = useMemo(
    () => debounce(updateWatchTime, 1000),
    [updateWatchTime]
  );

  useEffect(() => {
    let id;
    if (playing) {
      id = setInterval(() => {
        debounceUpdateWatchTime(videoWatchTime);
      }, 10000);
    }
    return () => {
      debounceUpdateWatchTime(videoWatchTime);
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceUpdateWatchTime, playing]);

  useEffect(() => {
    if (mobileControls === true) {
      setTimeout(() => {
        if (mobileControls) {
          setMobileControls(false);
        }
      }, 10000);
    }
  }, [mobileControls]);

  useEffect(() => {
    if (
      [SUBSCRIPTION_PLAN_KEYS.FREE, SUBSCRIPTION_PLAN_KEYS.GUEST].includes(
        currentUser?.effectivePlan
      ) ||
      (isPremium &&
        ((alignValue === 'Video' && !previewVideo) ||
          (alignValue === 'Audio' && !previewAudio)))
    )
      setShowSubscribeModal(true);
  }, [
    currentUser,
    requiredPlan,
    previewVideo,
    alignValue,
    previewAudio,
    isPremium
  ]);

  const onPause = () => {
    setPlaying(false);
    setPlaybackOpen(false);
    setSettingOpen(false);
    setSubtitleOpen(false);
    updateWatchTime(watchTime);
    if (alignValue === 'Video') {
      trackEvent({
        category: previewVideo
          ? MATOMO_CATEGORY.PREVIEW_PODCAST_VIDEO
          : MATOMO_CATEGORY.PODCAST_VIDEO,
        action: MATOMO_ACTION.VIDEO_PAUSE,
        name: JSON.stringify({
          ...(sessionData?.user && {
            email: sessionData?.user?.email
          }),
          siteId: process.env.NEXT_APP_MATOMO_SITE_ID,
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.VIDEO_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: played,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
      gaEvent({
        category: MATOMO_CATEGORY.PODCAST_VIDEO,
        action: MATOMO_ACTION.VIDEO_PAUSE,
        name: JSON.stringify({
          ...(sessionData?.user && {
            userId: sessionData?.user?.id
          }),
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.VIDEO_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: played,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
    } else {
      trackEvent({
        category: previewAudio
          ? MATOMO_CATEGORY.PREVIEW_PODCAST_AUDIO
          : MATOMO_CATEGORY.PODCAST_AUDIO,
        action: MATOMO_ACTION.AUDIO_PAUSE,
        name: JSON.stringify({
          ...(sessionData?.user && {
            email: sessionData?.user?.email
          }),
          siteId: process.env.NEXT_APP_MATOMO_SITE_ID,
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.PODCAST_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: VIDEOS?.[0]?.resources,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
      gaEvent({
        category: MATOMO_CATEGORY.PODCAST_AUDIO,
        action: MATOMO_ACTION.AUDIO_PAUSE,
        name: JSON.stringify({
          ...(sessionData?.user && {
            userId: sessionData?.user?.id
          }),
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.PODCAST_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: VIDEOS?.[0]?.resources,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
    }
  };

  const onEnded = () => {
    if (
      (previewVideo && alignValue === 'Video') ||
      (previewAudio && alignValue === 'Audio')
    ) {
      setShowSubscribeModal(true);
    }
    if (alignValue === 'Video') {
      trackEvent({
        category: previewVideo
          ? MATOMO_CATEGORY.PREVIEW_PODCAST_VIDEO
          : MATOMO_CATEGORY.PODCAST_VIDEO,
        action: MATOMO_ACTION.VIDEO_END,
        name: JSON.stringify({
          ...(sessionData?.user && {
            email: sessionData?.user?.email
          }),
          siteId: process.env.NEXT_APP_MATOMO_SITE_ID,
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.VIDEO_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: played,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
      gaEvent({
        category: MATOMO_CATEGORY.PODCAST_VIDEO,
        action: MATOMO_ACTION.VIDEO_END,
        name: JSON.stringify({
          ...(sessionData?.user && {
            userId: sessionData?.user?.id
          }),
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.VIDEO_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: played,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
    } else {
      trackEvent({
        category: previewAudio
          ? MATOMO_CATEGORY.PREVIEW_PODCAST_AUDIO
          : MATOMO_CATEGORY.PODCAST_AUDIO,
        action: MATOMO_ACTION.AUDIO_END,
        name: JSON.stringify({
          ...(sessionData?.user && {
            email: sessionData?.user?.email
          }),
          siteId: process.env.NEXT_APP_MATOMO_SITE_ID,
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.PODCAST_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: VIDEOS?.[0]?.resources,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
      gaEvent({
        category: MATOMO_CATEGORY.PODCAST_AUDIO,
        action: MATOMO_ACTION.AUDIO_END,
        name: JSON.stringify({
          ...(sessionData?.user && {
            userId: sessionData?.user?.id
          }),
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.PODCAST_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: VIDEOS?.[0]?.resources,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
    }

    if (nextVideo) {
      const { order, slug, type, videoSlug } = nextVideo;
      const nextVideoPath = withNextVideoQuery(
        `${ROUTES.VIDEOS}/${videoSlug}`,
        slug,
        order,
        type
      );
      router.push(nextVideoPath);
    }
  };

  const onReady = (readyRef) => {
    // eslint-disable-next-line no-undef
    const playerElement = document?.getElementById('custom-video-player');
    const videoElement = playerElement?.querySelector('video');
    videoElement.onvolumechange = () => {
      setMuted(videoElement?.muted);
      showUnmuteButton.current = false;
    };
    // for matomo tracking
    videoElement.setAttribute('data-matomo-title', VIDEOS?.[0]?.title);
    videoElement.setAttribute('data-piwik-title', VIDEOS?.[0]?.title);
    videoElement.setAttribute('title', VIDEOS?.[0]?.title);
    videoElement.setAttribute('alt', VIDEOS?.[0]?.title);
    window?.Matomo?.MediaAnalytics?.scanForMedia();
    const subtitleData = Object?.entries(videoElement?.textTracks)
      ?.map((item) => {
        return nth(item, 1);
      })
      ?.filter((track) => {
        return (
          track?.kind === TRACK_KIND?.SUBTITLES ||
          track?.kind === TRACK_KIND?.CAPTIONS
        );
      });
    const chapterData = Object?.entries(videoElement?.textTracks)
      ?.map((item) => {
        return nth(item, 1);
      })
      ?.filter((track) => {
        return track?.kind === TRACK_KIND?.CHAPTERS;
      });
    if (videoElement.paused && !isReady) setPlaying(false);
    else setPlaying(true);
    setSubtitleTracksData(subtitleData);
    setChapters(chapterData);
    setPlayerRef(readyRef);
    setIsReady(true);
  };

  const onDuration = (movieDuration) => {
    setDuration(movieDuration);
  };

  const onProgress = React.useCallback(
    // eslint-disable-next-line no-shadow
    ({ played, loaded, playedSeconds }) => {
      // We only want to update time slider if we are not currently seeking
      if (!seeking) {
        setPlayed(played);
        setLoaded(loaded);
        setPlayedSeconds(playedSeconds);
        videoWatchTime = playedSeconds;
        const chapter =
          nth(chapters, 0)?.cues?.length > 0 &&
          Object?.entries(nth(chapters, 0)?.cues)?.find(
            (item) =>
              playedSeconds?.toFixed(0) >= nth(item, 1)?.startTime &&
              playedSeconds?.toFixed(0) < nth(item, 1)?.endTime
          );
        setChapterTitle(nth(chapter, 1)?.text);
      }
    },
    [seeking, chapters]
  );

  const onSeekChange = React.useCallback(
    async (event) => {
      const time = parseFloat(event?.currentTarget?.value);
      setPlayed(time);
      playerRef?.seekTo(time);
      setPlaybackOpen(false);
      setSettingOpen(false);
      setSubtitleOpen(false);
    },
    [playerRef]
  );

  const handleBack15 = React.useCallback(async () => {
    try {
      const back15 = duration * played - 15;
      const time = back15 <= 0 ? 0 : back15 / duration;
      setPlaybackOpen(false);
      setSettingOpen(false);
      setSubtitleOpen(false);
      return playerRef?.seekTo(time);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('handleBack error =>', err);
    }
  }, [duration, played, playerRef]);

  const handleForward15 = React.useCallback(async () => {
    try {
      const forward15 = duration * played + 15;
      if (forward15 >= duration) return false;
      const time = forward15 / duration;
      setPlaybackOpen(false);
      setSettingOpen(false);
      setSubtitleOpen(false);
      return playerRef?.seekTo(time);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('handleForward error =>', err);
    }
  }, [duration, played, playerRef]);

  const handleVolumeChange = (newValue) => {
    setVolume(parseFloat(newValue / 100));
    setMuted(newValue === 0);
    setPlaybackOpen(false);
    setSettingOpen(false);
    setSubtitleOpen(false);
  };

  const hanldeMute = () => {
    setMuted(!muted);
    setPlaybackOpen(false);
    setSettingOpen(false);
    setSubtitleOpen(false);
  };

  const onCaptionClick = (event, track) => {
    event?.preventDefault();
    setCaptionVisible(true);
    // eslint-disable-next-line no-undef
    const playerElement = document?.getElementById('custom-video-player');
    const videoElement = playerElement?.querySelector('video');
    if (videoElement?.textTracks?.length > 0) {
      const showingTracks = Object?.entries(videoElement?.textTracks)?.find(
        (item) => {
          return (
            (nth(item, 1)?.kind === TRACK_KIND?.SUBTITLES ||
              nth(item, 1)?.kind === TRACK_KIND?.CAPTIONS) &&
            nth(item, 1)?.mode === TRACK_MODE?.SHOWING
          );
        }
      );
      if (showingTracks?.length > 0) {
        videoElement.textTracks[nth(showingTracks, 0)].mode =
          TRACK_MODE?.DISABLED;
      }
      const tracks = Object.entries(videoElement?.textTracks)?.find((item) => {
        return (
          (nth(item, 1)?.kind === TRACK_KIND?.SUBTITLES ||
            nth(item, 1)?.kind === TRACK_KIND?.CAPTIONS) &&
          nth(item, 1)?.label === track?.label
        );
      });
      videoElement.textTracks[nth(tracks, 0)].mode = TRACK_MODE?.SHOWING;
    }
    // TODO - Hide caption menu once any button is selected
  };
  const onChangeSubTitle = () => {
    setSubtitleOpen(false);
  };
  const onChangeBitrate = (value) => {
    setSettingOpen(false);
    const internalPlayer = playerRef?.getInternalPlayer('hls');
    if (internalPlayer) {
      // currentLevel expect to receive an index of the levels array
      internalPlayer.currentLevel = value;
      setPlayerRef({ ...playerRef, ...internalPlayer });
    }
  };

  const onPlaybackRate = (rate) => {
    setPlaybackRate(rate);
    setPlaybackOpen(!playbackOpen);
  };

  const handleShowCaptions = () => {
    setCaptionVisible(!captionVisible);
    // eslint-disable-next-line no-undef
    const playerElement = document?.getElementById('custom-video-player');
    const videoElement = playerElement?.querySelector('video');
    if (videoElement?.textTracks?.length > 0) {
      const showingTracks = Object?.entries(videoElement?.textTracks)?.find(
        (item) => {
          return (
            (nth(item, 1)?.kind === TRACK_KIND?.SUBTITLES ||
              nth(item, 1)?.kind === TRACK_KIND?.CAPTIONS) &&
            nth(item, 1)?.mode === TRACK_MODE?.SHOWING
          );
        }
      );
      if (showingTracks?.length > 0) {
        videoElement.textTracks[nth(showingTracks, 0)].mode =
          TRACK_MODE?.DISABLED;
        setActiveCaption(videoElement.textTracks[nth(showingTracks, 0)].label);
      } else {
        const disabledTracks = Object?.entries(videoElement?.textTracks)?.find(
          (item) => {
            return (
              nth(item, 1)?.kind === TRACK_KIND?.SUBTITLES ||
              (nth(item, 1)?.kind === TRACK_KIND?.CAPTIONS &&
                nth(item, 1)?.label === activeCaption)
            );
          }
        );
        if (disabledTracks?.length > 0) {
          videoElement.textTracks[nth(disabledTracks, 0)].mode =
            TRACK_MODE?.SHOWING;
        }
      }
    }
  };

  const subtitleContent = () => {
    return (
      <div className="video-dropdown">
        <div className="video-setting-icon" onClick={subtitleClick}>
          <ClosedCaptioning
            className={captionVisible ? 'active-caption pointer' : 'pointer'}
            size={32}
            onClick={handleShowCaptions}
          />
        </div>

        <Select
          bordered={false}
          defaultValue={defaultSubtitle}
          placement="bottomRight"
          onSelect={onChangeSubTitle}
          onChange={onChangeSubTitle}
          open={subtitleOpen}
          getPopupContainer={(trigger) => trigger?.parentNode}
          // eslint-disable-next-line react/no-unstable-nested-components
          dropdownRender={(menu) => (
            <>
              <div className="text-meta-bold dropdown-header">
                Subtitles
                <X color="#1A1714" size={24} onClick={subtitleClick} />
              </div>
              {menu}
            </>
          )}
        >
          {subtitleTracksData?.map((track) => {
            return (
              <Option key={track?.language} value={track?.language}>
                <span
                  onClick={(event) => {
                    onCaptionClick(event, track);
                  }}
                >
                  {track?.label}
                </span>
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const qualityContent = () => {
    return (
      <div className="video-dropdown">
        <div className="video-setting-icon" onClick={settingClick}>
          <GearSix className="pointer" size={32} />
        </div>
        <Select
          bordered={false}
          value={playerRef?.getInternalPlayer('hls')?.manualLevel}
          onChange={onChangeBitrate}
          onSelect={onChangeBitrate}
          open={settingOpen}
          placement="bottomRight"
          getPopupContainer={(trigger) => trigger?.parentNode}
          // eslint-disable-next-line react/no-unstable-nested-components
          dropdownRender={(menu) => (
            <>
              <div className="text-meta-bold dropdown-header">
                Quality
                <X color="#1A1714" size={24} onClick={settingClick} />
              </div>
              {menu}
            </>
          )}
        >
          <Option key="auto" value={-1}>
            Auto
          </Option>

          {playerRef?.getInternalPlayer('hls')?.levels?.map((level, id) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Option key={id} value={id}>
                {level?.height}p
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const playbackRateContent = () => {
    return (
      <div className="video-dropdown">
        <div className="video-setting-icon" onClick={playbackClick}>
          <Gauge color="#fff" size={28} />
        </div>
        <Select
          bordered={false}
          className="playback-rate-selector"
          defaultValue={1}
          open={playbackOpen}
          placement="topRight"
          onChange={onPlaybackRate}
          onSelect={onPlaybackRate}
          getPopupContainer={(trigger) => trigger?.parentNode}
          // eslint-disable-next-line react/no-unstable-nested-components
          dropdownRender={(menu) => (
            <>
              <div className="text-meta-bold dropdown-header">
                Playback speed
                <X color="#1A1714" size={24} onClick={playbackClick} />
              </div>
              {menu}
            </>
          )}
        >
          {PLAYBACK_RATES?.map((rate) => {
            return (
              <Option key={rate} value={rate}>
                {rate}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const handleMouseOver = () => {
    setShowControls(true);
  };

  const handleMouseOut = () => {
    setShowControls(false);
    setSettingOpen(false);
    setPlaybackOpen(false);
    setSubtitleOpen(false);
  };

  const handleChapter = (item) => {
    playerRef?.seekTo(item?.startTime);
  };

  const onPlay = () => {
    const internalPlayer = document?.querySelector('video');

    if (
      internalPlayer &&
      !isAuth &&
      !isVideoWatched.current &&
      showModal &&
      window.location === window.parent.location
    ) {
      const count = +Storage.get(Storage.keys.VIDEO_WATCH_COUNT);

      // if (count >= maxAllowedWatchCount.current ?? 3) {
      //   setShowSignUpModal(true);
      //   setMuted(false);
      //   internalPlayer?.pause();
      //   return;
      // }
      const videoWatchCount = (isNumber(count) ? count : 0) + 1;
      Storage.set({
        key: Storage.keys.VIDEO_WATCH_COUNT,
        value: videoWatchCount
      });
      isVideoWatched.current = true;
    }

    if (alignValue === 'Video') {
      setIsVideoPlayed(false);
      trackEvent({
        category: previewVideo
          ? MATOMO_CATEGORY.PREVIEW_PODCAST_VIDEO
          : MATOMO_CATEGORY.PODCAST_VIDEO,
        action: MATOMO_ACTION.VIDEO_PLAY,
        name: JSON.stringify({
          ...(sessionData?.user && {
            email: sessionData?.user?.email
          }),
          siteId: process.env.NEXT_APP_MATOMO_SITE_ID,
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.VIDEO_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: played,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
      gaEvent({
        category: MATOMO_CATEGORY.PODCAST_VIDEO,
        action: MATOMO_ACTION.VIDEO_PLAY,
        name: JSON.stringify({
          ...(sessionData?.user && {
            userId: sessionData?.user?.id
          }),
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.VIDEO_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: played,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
    } else {
      trackEvent({
        category: previewAudio
          ? MATOMO_CATEGORY.PREVIEW_PODCAST_AUDIO
          : MATOMO_CATEGORY.PODCAST_AUDIO,
        action: MATOMO_ACTION.AUDIO_PLAY,
        name: JSON.stringify({
          ...(sessionData?.user && {
            email: sessionData?.user?.email
          }),
          siteId: process.env.NEXT_APP_MATOMO_SITE_ID,
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.PODCAST_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: VIDEOS?.[0]?.resources,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
      gaEvent({
        category: MATOMO_CATEGORY.PODCAST_AUDIO,
        action: MATOMO_ACTION.AUDIO_PLAY,
        name: JSON.stringify({
          ...(sessionData?.user && {
            userId: sessionData?.user?.id
          }),
          mediaId: VIDEOS?.[0]?.id,
          mediaTitle: VIDEOS?.[0]?.title,
          playerName: VIDEO_PLAYER_NAME,
          mediaType: MODULE_TYPES.PODCAST_PLAYER,
          mediaResource: VIDEOS?.[0]?.resources,
          mediaStatus: status,
          mediaLength: VIDEOS?.[0]?.time,
          mediaProgress: VIDEOS?.[0]?.resources,
          mediaFullScreen: false
        }),
        value: TRACK_VALUE.SUCCESS
      });
    }

    setPlaying(true);
    setPlaybackOpen(false);
    setSettingOpen(false);
    setSubtitleOpen(false);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleSignUpModalClose = () => {
    setShowSignUpModal(false);
  };

  const options = [
    {
      label: (
        <div className="d-flex align-center">
          <Headphones />
          <span className="ml-4">Audio</span>
        </div>
      ),
      value: 'Audio'
    },
    {
      label: (
        <div className="d-flex align-center">
          <VideoCamera />
          <span className="ml-4">Video</span>
        </div>
      ),
      value: 'Video'
    }
  ];

  return (
    <>
      {showSubscribeModal && (
        <SubscriptionBlock
          primaryAction
          thumb={
            isAuthenticated
              ? PREMIUM_BLOCK_IMAGE?.value ||
                staticUiLabels?.PREMIUM_BLOCK_IMAGE
              : WITHOUT_LOGIN_PREMIUM_BLOCK_LOGIN_IMAGE?.value ||
                staticUiLabels?.WITHOUT_LOGIN_PREMIUM_BLOCK_LOGIN_IMAGE
          }
          title={
            isAuthenticated
              ? PREMIUM_BLOCK_TITLE?.value || staticUiLabels.PREMIUM_BLOCK_TITLE
              : staticUiLabels?.WITHOUT_LOGIN_PREMIUM_BLOCK_TITLE
          }
          description={
            isAuthenticated
              ? PREMIUM_BLOCK_DESC?.value || staticUiLabels.PREMIUM_BLOCK_DESC
              : WITHOUT_LOGIN_PREMIUM_BLOCK_DESC?.value ||
                staticUiLabels?.WITHOUT_LOGIN_PREMIUM_BLOCK_DESC
          }
          btnLabel={
            isAuthenticated
              ? PREMIUM_BLOCK_BUTTON?.value ||
                staticUiLabels.PREMIUM_BLOCK_BUTTON
              : WITHOUT_LOGIN_PREMIUM_BLOCK_BUTTON?.value ||
                staticUiLabels?.WITHOUT_LOGIN_PREMIUM_BLOCK_BUTTON
          }
          loginText={
            !isAuthenticated && WITHOUT_LOGIN_PREMIUM_BLOCK_LOGIN_TEXT?.value
          }
          modal={showSubscribeModal}
          setShowSubscribeModal={setShowSubscribeModal}
          previewVideo={previewVideo}
          planNames={planNames}
          lowestPrice={lowestPrice}
        />
      )}
      {showModal && (
        <SignupCTAModal
          isAuth={isAuth}
          open={showSignUpModal}
          onClose={handleSignUpModalClose}
        />
      )}
      <div className={!video ? 'hide-podcast-segment' : 'podcast-segment'}>
        <ConfigProvider
          theme={{
            token: {
              colorText: 'black',
              colorBgElevated: '#fff',
              colorBgLayout: 'white',
              controlHeight: '40',
              colorTextLabel: 'black',
              colorFillSecondary: 'none',
              padding: 10,
              colorBorder: 'red',
              borderRadius: 30,
              borderRadiusLG: 30
            }
          }}
        >
          <Segmented
            className="podcast-segmented"
            defaultValue="Audio"
            onChange={(value) => setAlignValue(value)}
            options={options}
          />
        </ConfigProvider>
      </div>
      <div
        className={`player-wrapper ${mobileControls && 'mobile-controls'}`}
        onMouseMove={handleMouseOver}
        onTouchStart={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <PosterImage
          url={alignValue === 'Audio' ? audios?.[0]?.thumb : VIDEOS?.[0]?.thumb}
          isIos={isIOS}
          isVideoPlayed={alignValue === 'Audio' ? true : isVideoPlayed}
        />
        <IOSWrapper show={isIOS || isSafari}>
          {showUnmuteButton.current && (
            <div className="tap-to-unmute" onClick={() => setMuted(false)}>
              <SpeakerSlash size={24} />
              Tap To Unmute
            </div>
          )}
        </IOSWrapper>
        <ReactPlayer
          className={`custom-player ${
            moduleType === MODULE_TYPES.PODCAST_PLAYER &&
            'custom-podcast-player'
          }`}
          url={alignValue === 'Audio' ? audio : video}
          data-matomo-title={VIDEOS?.[0]?.title}
          width="100%"
          height="100%"
          id="custom-video-player"
          playsinline
          controls={isIOS}
          loop={false}
          volume={volume}
          muted={muted}
          playing={playing}
          playbackRate={playbackRate}
          onReady={onReady}
          onPause={onPause}
          onPlay={onPlay}
          onDuration={onDuration}
          onProgress={onProgress}
          onEnded={onEnded}
          onSeek={debounceUpdateWatchTime}
          config={{
            file: {
              attributes: {
                crossOrigin: 'true'
              },
              tracks: videoTracks
            }
          }}
        />
        {previewAudio && (
          <PreviewIndicator
            duration={duration}
            played={played}
            setShowSignUpModal={setShowSubscribeModal}
            isPodcast
          />
        )}
      </div>
      <div className="custom-control-wrap">
        {(playbackOpen || settingOpen || subtitleOpen) && (
          <span className="overlay-outside" onClick={outsideClick} />
        )}
        <div className="controlitem-bottom">
          <div className="duration-bar">
            <Progress loaded={loaded} played={played} onChange={onSeekChange} />
          </div>
          <div className="controls-items">
            <div className="left-controls">
              <div className="play-box">
                <div className="play-duration">
                  <DurationTime seconds={duration * played} />/
                  <DurationTime seconds={duration} />
                </div>
              </div>
              <div className="volume-section ">
                {muted ? (
                  <SpeakerSlash
                    size={32}
                    onClick={hanldeMute}
                    className="icon"
                  />
                ) : (
                  <SpeakerHigh
                    size={32}
                    onClick={hanldeMute}
                    className="icon"
                  />
                )}
                <Slider
                  min={0}
                  max={100}
                  value={muted ? 0 : volume * 100}
                  onChange={handleVolumeChange}
                  className="slider"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="controlitem-top d-flex justify-between ">
          <div className="text-md text-white-90">
            {typeof document !== 'undefined' &&
              (document?.fullscreenElement ||
                document?.webkitCurrentFullScreenElement) &&
              videoTitle}
          </div>
          <div className="d-flex text-n-0 gap-16">
            {!!subtitleTracksData?.length && subtitleContent()}

            {qualityContent()}
          </div>
        </div>
        <div className="controlitem-middle">
          <div className="play-pause-btn">
            <svg
              onClick={handleBack15}
              className="skip-btn"
              cursor="pointer"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 10.4942L10.5294 9.47461V15.4111"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.2907 9.47461H13.732L13.3496 12.43C13.6863 12.0957 14.1416 11.9081 14.6162 11.9081C15.0909 11.9081 15.5462 12.0957 15.8829 12.43C16.0494 12.5952 16.1815 12.7917 16.2717 13.0082C16.3618 13.2247 16.4083 13.4569 16.4083 13.6913C16.4083 13.9258 16.3618 14.158 16.2717 14.3745C16.1815 14.5909 16.0494 14.7874 15.8829 14.9526C15.5462 15.287 15.0909 15.4746 14.6162 15.4746C14.1416 15.4746 13.6863 15.287 13.3496 14.9526"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.38867 8.93639H2.05979V4.60645"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.73955 18.7336C7.07113 20.0666 8.76805 20.9746 10.6156 21.3428C12.4632 21.711 14.3785 21.5228 16.1192 20.802C17.8598 20.0812 19.3477 18.8603 20.3945 17.2936C21.4413 15.7269 22 13.8849 22 12.0005C22 10.1161 21.4413 8.27408 20.3945 6.70739C19.3477 5.1407 17.8598 3.91975 16.1192 3.19898C14.3785 2.47822 12.4632 2.29003 10.6156 2.65822C8.76805 3.02641 7.07113 3.93443 5.73955 5.26743L2.06 8.93706"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className="play-pause-btn pointer" onClick={handlePlayPause}>
              {playing ? (
                <Pause size={80} weight="fill" />
              ) : (
                <Play size={80} weight="fill" />
              )}
            </div>

            <svg
              onClick={handleForward15}
              className="skip-btn"
              cursor="pointer"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0254 8.93639H22.3553V4.60645"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.6746 18.7336C17.3427 20.0666 15.6454 20.9746 13.7973 21.3428C11.9492 21.711 10.0335 21.5228 8.29243 20.802C6.55135 20.0812 5.06316 18.8603 4.0161 17.2936C2.96904 15.7269 2.41016 13.8849 2.41016 12.0005C2.41016 10.1161 2.96904 8.27408 4.0161 6.70739C5.06316 5.1407 6.55135 3.91975 8.29243 3.19898C10.0335 2.47822 11.9492 2.29003 13.7973 2.65822C15.6454 3.02641 17.3427 3.93443 18.6746 5.26743L22.355 8.93706"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.72656 10.4942L9.256 9.47461V15.4111"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.0193 9.47463H12.4605L12.0781 12.4301C12.4148 12.0957 12.8702 11.9081 13.3448 11.9081C13.8194 11.9081 14.2747 12.0957 14.6114 12.4301C14.7779 12.5953 14.91 12.7918 15.0002 13.0082C15.0904 13.2247 15.1368 13.4569 15.1368 13.6913C15.1368 13.9258 15.0904 14.158 15.0002 14.3745C14.91 14.5909 14.7779 14.7874 14.6114 14.9526C14.2747 15.287 13.8194 15.4746 13.3448 15.4746C12.8702 15.4746 12.4148 15.287 12.0781 14.9526"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="playback-rate-content podcast-dropdown">
              {playbackRateContent()}
            </div>
          </div>
        </div>
      </div>
      <div className="ml-20">
        <Chapters
          chapters={chapters}
          time={playedSeconds}
          handleChapter={handleChapter}
        />
      </div>
    </>
  );
}

export default CustomPodcastPlayer;
