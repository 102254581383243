import { Button, Progress } from 'antd';
import React from 'react';

function PreviewIndicator({ setShowSignUpModal, played, isPodcast = false }) {
  const playedVideoInPercentage = played * 100;
  return (
    <div className={`${isPodcast && 'sticky w-full'} preview-indicator-box`}>
      <div className="preview-indicator-timer">
        <Progress
          type="circle"
          percent={playedVideoInPercentage}
          size={40}
          strokeColor="#fd5704"
          showInfo={false}
        />
      </div>
      <div className={`${isPodcast && 'w-full'} preview-indicator-content`}>
        <div className="preview-indicator-content-title">Preview Mode</div>
        <div className="preview-indicator-content-subline">
          Become a member for full video access
        </div>
      </div>
      <Button
        type="primary"
        onClick={() => {
          setShowSignUpModal(true);
        }}
      >
        Subscribe
      </Button>
    </div>
  );
}

export default PreviewIndicator;
