import { useMemo, useState } from 'react';
import {
  deviceType,
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from 'react-device-detect';
import { v4 as uuidV4 } from 'uuid';
import { Storage } from './utils';

const getDeviceId = () => {
  if (typeof window === 'undefined') return '';
  const id = Storage.get(Storage.keys.DEVICE_ID);
  if (id) return id;
  const deviceId = uuidV4();
  Storage.set({ key: Storage.keys.DEVICE_ID, value: deviceId });
  return deviceId;
};

const useDeviceInfo = () => {
  const [deviceId] = useState(() => getDeviceId());

  return useMemo(
    () => ({
      id: deviceId,
      model: mobileModel,
      os: {
        name: osName,
        version: osVersion
      },
      type: deviceType,
      vendor: mobileVendor
    }),
    [deviceId]
  );
};

export default useDeviceInfo;
