import { Button, Typography } from 'antd';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { X } from 'phosphor-react';
import { useMemo } from 'react';
import {
  PAGE_GROUPS,
  ROUTES,
  SUBSCRIPTION_PLAN_KEYS
} from '../../../../common/constants';
import usePageGroup from '../../../../common/usePageGroup';
import { encode } from '../../../../common/utils';
import { useApp } from '../../../../providers/AppContext';
import CustomAnchor from '../../../CustomAnchor';
import SubscriptionBlockLoading from '../../../skeleton/SubscriptionBlockLoading';

const { Text, Title } = Typography;
function SubscriptionBlock({
  primaryAction,
  thumb,
  title = `You're using a limited version.`,
  description = 'Join the Insider Club and Unlock the Full Potential',
  btnLabel = 'SUBSCRIBE',
  modal,
  setShowSubscribeModal,
  previewVideo = '',
  loginText,
  lowestPrice,
  planNames
}) {
  const {
    state: { accessToken, currentUser, loading, isAuthenticated }
  } = useApp();

  const router = useRouter();
  const pathname = usePathname();

  const { getRandomPath } = usePageGroup();

  const hasActivePlan =
    currentUser &&
    ![SUBSCRIPTION_PLAN_KEYS.FREE, SUBSCRIPTION_PLAN_KEYS.GUEST].includes(
      currentUser?.currentPlan
    );

  const hasActiveHeavyWeightPlan =
    hasActivePlan &&
    [
      SUBSCRIPTION_PLAN_KEYS.HEAVYWEIGHT_ANNUALLY,
      SUBSCRIPTION_PLAN_KEYS.HEAVYWEIGHT_MONTHLY
    ].includes(currentUser?.currentPlan);
  const plans = ['Free', 'Contender', 'Middleweight', 'Heavyweight'];
  const currentPlanWeight = Math.floor(
    currentUser?.currentSubscriptionPlan?.planWeight
  );
  const plansToUpgrade =
    currentPlanWeight > 0
      ? plans.slice(currentPlanWeight + 1).join(' or ')
      : '';

  const buttonText = useMemo(() => {
    if (loading) return '';
    if (hasActiveHeavyWeightPlan) return `Change Subscription`;
    if (hasActivePlan) return `Upgrade`;
    return btnLabel;
  }, [hasActiveHeavyWeightPlan, hasActivePlan, loading, btnLabel]);

  const handleRedirect = () => {
    const path = getRandomPath(PAGE_GROUPS.SUBSCRIPTION);
    if (path)
      router.push({
        pathname: path,
        query: {
          redirectPath: pathname
        }
      });
  };
  return (
    <section
      className={`subscription-block-wrapper ${
        modal ? 'modal-subscription' : ''
      }`}
    >
      <Button
        className="sb-cancel"
        type="text"
        onClick={() => {
          setShowSubscribeModal(false);
        }}
      >
        <X size={26} />
      </Button>
      <div className="container">
        <div className="subscription-block">
          <div className="sb-content">
            <Title level={5} className="sb-content-description">
              {hasActivePlan
                ? `You are a ${currentUser?.currentSubscriptionPlan?.planName}`
                : description}
            </Title>
            <Title level={4}>
              {hasActivePlan ? (
                <span>
                  Upgrade to <b>{plansToUpgrade}</b> to watch this content
                </span>
              ) : (
                <span>
                  Watch this video and access full video library by becoming a
                  &nbsp;
                  <b>BREITBART FIGHT CLUB</b> member.
                </span>
              )}
            </Title>
            {!hasActivePlan && (
              <div className="w-full text-left">
                {lowestPrice && (
                  <span className="plan-price">
                    Plans starting from ${lowestPrice}/month
                  </span>
                )}
                <div className="d-flex flex-wrap gap-8 mt-4 w-full justify-center">
                  {planNames?.map((item) => {
                    return (
                      <div className="plan-name" key={item}>
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {primaryAction && (
              <Button
                className="cta-btn"
                loading={loading}
                size="small"
                type="primary"
                onClick={handleRedirect}
              >
                {buttonText}
              </Button>
            )}
            {!isAuthenticated && (
              <div className="sb-footer">
                <span>{loginText}</span> &nbsp;
                <CustomAnchor
                  href={{
                    pathname: ROUTES.LOGIN,
                    query: {
                      q: encode(pathname)
                    }
                  }}
                >
                  Login
                </CustomAnchor>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

SubscriptionBlock.Loading = function Loading() {
  return <SubscriptionBlockLoading />;
};

export default SubscriptionBlock;
